<template>
	<div style="margin:15px 25px 15px 20px;">
		<div style="margin:15px 20px;">
			<p class="mt-4 black6 font-12 weight-400">{{ news.time }}</p>
			<p class="mt-3 font-18 black8 line-height-32">{{ news.title }}</p>
			<p class="black6 font-13">{{ news.subtitle }}</p>
			<div class="myp mt-4 line-height-32" v-html="news.content"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Newsdetail',
	props: {
		news: {
			type: Array,
			default: function () { return []; }
		}
	},
	methods: {
	}
}
</script>
<style scoped></style>
