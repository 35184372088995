<template>
	<div>
		<div v-if="this.$store.state.language == 'CN'" class="mainbody">
			<Newsdetail :news="newsdata" />
		</div>
	</div>
</template>
<script>
import Newsdetail from "@/components/newsdetail";
export default {
	data() {
		return {
			newsdata: []
		}
	},
	components: {
		Newsdetail
	},
	mounted() {
		this.$nextTick(() => {
			const layui = window.layui;
			layui.use(["layer"], () => {
				var layer = layui.layer;
			});
		});
		let newsid = this.$route.query.id;
		this.getnews(newsid); //获取新闻
	},
	methods: {
		getnews(newsid) {
			// var index = layer.load();
			this.$axios.post('web/newsdetail/', { id: newsid }).then(res => {
				if (res.status == 'ok') {
					// layer.close(index);
					this.newsdata = res.data;
				}
			}).catch(err => { })
		}
	}
};
</script>
<style scoped>
::v-deep .modal-content {
	background-color: #000000;
	color: white;
	background: none;
	border: none;
}

::v-deep .modal-header {
	border: none;
}

::v-deep .modal-backdrop {
	opacity: 0.5;
}
</style>
